import React, { useEffect } from 'react';
import { ChakraProvider, getTheme } from '@wegroup/design-system';
import { ThemeName } from '../../../../design-system/src/theme/themes';
import { camelCase } from 'lodash';
import { MantineProvider } from '@mantine/core';
import useAuth from '../../features/authentication/hooks/useAuth';
import { getThemeObject } from '../../../../common/src/legacy/services/themingService';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { ThemeProvider as ExternalThemeProvider } from 'wg-fe-ui';
import useAppTheme from '../../hooks/useAppTheme';
import Fonts from '../../components/Fonts';
import { isSocialLoginUrl } from '../../features/authentication/constants/SocialAuth';
import MantineSelectStyling from '../../components/MantineSelectStyling';
import useFeatureFlags from '../../hooks/useFeatureFlags';

export const ThemeProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { isYellowHiveEnabled } = useFeatureFlags();
  const { distribution, campaign } = useAuth();

  const { appTheme: theme, setAppTheme, appThemeObject } = useAppTheme();
  /** HARDCODED FOR YELLOWHIVE */
  const appTheme = isYellowHiveEnabled ? 'yellowhive' : theme;
  /** ---------------------- */

  useEffect(() => {
    if (isSocialLoginUrl && campaign?.theme?.name) {
      setAppTheme(campaign?.theme.name);
    } else if (distribution?.theme?.name) {
      setAppTheme(distribution?.theme?.name);
    }
  }, [distribution, campaign]);

  const primaryColor =
    appThemeObject?.primary_color ||
    distribution?.theme?.primary_color ||
    campaign?.theme?.primary_color;

  const secondaryColor =
    appThemeObject?.secondary_color ||
    distribution?.theme?.secondary_color ||
    campaign?.theme?.secondary_color;

  const chakraThemeObject = getTheme(
    camelCase(appTheme) as ThemeName,
    primaryColor && secondaryColor
      ? {
          primary: primaryColor,
          secondary: secondaryColor,
        }
      : undefined,
  );

  const themeObject = {
    ...getThemeObject(appTheme, {
      primary: primaryColor,
      secondary: secondaryColor,
    }),
    ...chakraThemeObject.colors,
  };

  return (
    <MantineProvider theme={{ fontFamily: 'Circular' }}>
      <ChakraProvider theme={chakraThemeObject}>
        <Fonts />
        <MantineSelectStyling />
        <ExternalThemeProvider theme={themeObject} themeName={appTheme}>
          <StyledComponentsThemeProvider theme={themeObject}>
            {children}
          </StyledComponentsThemeProvider>
        </ExternalThemeProvider>
      </ChakraProvider>
    </MantineProvider>
  );
};
