import { useQuery } from 'react-query';
import { Pagination } from '../../../../../../types/Common';
import { wgApi } from '../../../../../app/src/features/api/wgApi';

export interface ThemeObject {
  name: string;
  primary_color: string;
  secondary_color: string;
  distribution_specific: boolean | null;
}

export interface ThemeResponse {
  items: ThemeObject[];
  pagination: Pagination;
}

export const getThemes = async () => {
  const { data, isError } = await wgApi.get<ThemeResponse>(`/v1/api/themes`);
  if (isError) throw new Error('Unable to fetch themes');
  return data;
};

export const getUseThemesQKey = () => ['themes'];

export const useThemes = (enabled = true) => {
  return useQuery(getUseThemesQKey(), () => getThemes(), {
    refetchOnWindowFocus: false,
    enabled,
  });
};
