interface ShadedColor {
  main: string;
  50: string;
  100: string;
  150: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  750: string;
  800: string;
  850: string;
  900: string;
}

interface ColorPalette {
  primary: ShadedColor;
  secondary: ShadedColor;
}

/**
 * Converts a hex color to RGB
 */
const hexToRgb = (hex: string): [number, number, number] => {
  const sanitizedHex = hex.replace('#', '');
  const r = parseInt(sanitizedHex.substring(0, 2), 16);
  const g = parseInt(sanitizedHex.substring(2, 4), 16);
  const b = parseInt(sanitizedHex.substring(4, 6), 16);
  return [r, g, b];
};

/**
 * Converts RGB color to hex
 */
const rgbToHex = (r: number, g: number, b: number): string => {
  return (
    '#' +
    [r, g, b]
      .map((x) => {
        const hex = Math.max(0, Math.min(255, Math.round(x))).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      })
      .join('')
  );
};

/**
 * Lightens or darkens a color by a percentage
 * @param hex - Hex color code
 * @param percent - Percentage to lighten or darken (-1 to 1)
 * @returns Lightened or darkened hex color
 */
export const shadeColor = (hex: string, percent: number): string => {
  const [r, g, b] = hexToRgb(hex);

  const amount = percent < 0 ? 0 : 255;
  const factor = Math.abs(percent);

  const newR = r + factor * (amount - r);
  const newG = g + factor * (amount - g);
  const newB = b + factor * (amount - b);

  return rgbToHex(newR, newG, newB);
};

/**
 * Generates color shades for a given base color
 */
const generateShades = (color: string): ShadedColor => {
  return {
    main: color,
    50: shadeColor(color, 0.85),
    100: shadeColor(color, 0.7),
    150: shadeColor(color, 0.6),
    200: shadeColor(color, 0.5),
    300: shadeColor(color, 0.35),
    400: shadeColor(color, 0.2),
    500: shadeColor(color, 0),
    600: shadeColor(color, -0.15),
    700: shadeColor(color, -0.3),
    750: shadeColor(color, -0.4),
    800: shadeColor(color, -0.5),
    850: shadeColor(color, -0.6),
    900: shadeColor(color, -0.7),
  };
};

/**
 * Generates a theme color palette from primary and secondary colors
 */
export const generateThemeColors = (
  primaryHex: string,
  secondaryHex: string,
): ColorPalette => {
  return {
    primary: generateShades(primaryHex),
    secondary: generateShades(secondaryHex),
  };
};
