import { ProspectObject } from '../../../../types/SMT';
import { Party } from '../types';

type PartyParam = ProspectObject | Partial<Party>;

/**
 * Returns the party name based on the fact if it is a CUSTOMER or a COMPANY by the party object.
 * @param party The party object where the name should be extracted from/
 * @returns Returns the name or an empty string if the type is not recognised.
 */
export const getPartyName = (party: PartyParam): string => {
  if (party.type === 'COMPANY') {
    return party.name ?? '';
  }
  if (party.type === 'CUSTOMER') {
    const { first_name, infix, last_name, initials } = party;
    const nameParts = first_name
      ? [first_name, infix, last_name]
      : [initials, infix, last_name];

    return nameParts.filter(Boolean).join(' ');
  }
  return '';
};
