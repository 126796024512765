import {
  ThemeObject,
  useThemes,
} from '@settings/src/features/office/api/getThemes';
import { setThemeName } from '../../../common/src/store/redux/slices/generalDataSlice';
import {
  useGlobalDispatch,
  useGlobalSelector,
} from '../../../common/src/utils/globalVariables';
import { ThemeName } from '../../../design-system/src/theme/themes';
import { isSocialLoginUrl } from '../features/authentication/constants/SocialAuth';
import useAuth from '../features/authentication/hooks/useAuth';

interface UseAppTheme {
  appTheme: ThemeName;
  appThemeObject?: ThemeObject;
  setAppTheme: (arg0: ThemeName) => void;
  resetAppTheme: () => void;
}

const useAppTheme = (): UseAppTheme => {
  const { distribution } = useAuth();
  const { data: themes } = useThemes(!isSocialLoginUrl);
  const globalDispatch = useGlobalDispatch();
  const appTheme =
    useGlobalSelector((state) => state.generalData.themeName) || 'orange';
  const appThemeObject = themes?.items.find((theme) => theme.name === appTheme);

  const setAppTheme = (theme: ThemeName) => {
    globalDispatch(setThemeName(theme));
  };

  const resetAppTheme = () => {
    if (!distribution?.theme?.name) return;
    globalDispatch(setThemeName(distribution.theme.name));
  };

  return {
    appTheme,
    appThemeObject,
    resetAppTheme,
    setAppTheme,
  };
};

export default useAppTheme;
