import { Address, LanguageStringMap, ToastSeverity } from './Common';
import { ReactNode } from 'react';
import { CompanyRegistration } from '../packages/parties/src/types';

export const isTextOptions = (options: unknown): options is TextOption[] => {
  return (options as TextOption) !== undefined;
};

export const isNumberOptions = (options: unknown): options is number[] => {
  return (options as number[]).every((item) => typeof item === 'number');
};

export interface OfferUris {
  [x: string]: { id: string; href: string }[];
}

export interface ReturnOptionProps {
  name: string;
  label: string;
  value?: unknown;
}

export interface CarInputs {
  key: string;
  name: string;
  value?: ReactNode;
  type: string;
  required?: boolean;
  tooltip?: string;
}

export interface DisabledObj {
  insurance_types?: string[];
  reason?: LanguageStringMap;
  severity?: ToastSeverity;
}

export interface DisabledCompanies {
  [x: string]: DisabledObj;
}

export interface Company {
  name: string;
  label: string;
  logo: string;
  insurances: string[];
  disabledObj?: DisabledObj;
}

export interface Accident {
  date: string;
  type?: string;
  liability: string;
}

export interface TextOption {
  [key: string]: LanguageStringMap | undefined;
}

export interface ProdFacMultiquotesResponse {
  info: Info;
  questionnaire: ProductFactoryQuestionnaire;
}

export interface Info {
  id: string;
  name: string;
  companies: string[];
  type: string;
}

export interface Advisory {
  assistant_question: LanguageStringMap;
  assistant_description: LanguageStringMap;
  assistant_answer?: string;
}

export interface Regex {
  js: string;
  py: string;
}

export interface Rules {
  mandatory?: boolean;
  input_type: string;
  data_type?: string | null;
  symbol?: string | null;
  enum?:
    | {
        [key: string]: LanguageStringMap | undefined;
      }[]
    | number[]
    | null;
  gt?: string | number | null;
  lt?: string | number | null;
  ge?: string | number | null;
  le?: string | number | null;
  min_length?: string | number | null;
  max_length?: string | number | null;
  regex?: Regex | null;
  mask?: string;
  sort?: 'asc' | 'desc' | null;
}

export interface Validator {
  url: string;
  body: string;
}

export interface Description {
  nl: string;
  en: string;
  fr: string;
}

export interface Condition {
  question_id: string;
  value: ReactNode;
  index?: number;
  inversed?: boolean;
}

export interface ConditionsObject {
  type: string;
  items: Array<Condition | ConditionsObject>;
}

export interface ErrorMessages {
  mandatory?: LanguageStringMap | null;
  gt?: LanguageStringMap | null;
  lt?: LanguageStringMap | null;
  ge?: LanguageStringMap | null;
  le?: LanguageStringMap | null;
  min_length?: LanguageStringMap | null;
  max_length?: LanguageStringMap | null;
  regex?: LanguageStringMap | null;
  input_type?: LanguageStringMap | null;
  data_type?: LanguageStringMap | null;
}

export interface ProductFactoryQuestion {
  id: string;
  tag?: string;
  field?: string;
  title?: LanguageStringMap | null;
  rules: Rules;
  value?: unknown;
  validator?: Validator;
  description?: Description;
  conditions?: Condition[];
  error_messages?: ErrorMessages;
  icon_key?: string;
  index?: number;
}

export interface ProductFactoryGroup {
  id: string;
  tag: string;
  advisory: Advisory;
  name: string;
  title?: LanguageStringMap;
  description?: Description;
  questions: ProductFactoryQuestion[];
  conditions?: Condition[];
  duplicate_questions?: string[];
  index?: number;
  /** Contains metadata to display the question correctly if it contains an index */
  duplicateQuestionData?: {
    amount: number;
    id: string;
  };
}

export interface ProductFactoryQuestionnaire {
  groups: ProductFactoryGroup[];
}

export interface AdditionalDriver {
  id: string;
  first_name: string;
  last_name: string;
  birth?: string;
  issue_license_date?: string;
  address?: Address;
  has_claims?: boolean;
  accidents?: Accident[];
}

export enum AnswerRouteType {
  INVALID_INPUT = 'INVALID_INPUT',
  GROUP_INCOMPLETE = 'GROUP_NOT_ANSWERED',
  GROUP_COMPLETE = 'GROUP_ANSWERED_CORRECTLY',
  FLOW_COMPLETED = 'FLOW_COMPLETED',
}

export interface AcknowledgementPayload {
  agreed?: boolean;
  key: string;
  text: string;
}

export interface OfferDocumentLink {
  label: string;
  url?: string;
}

export interface Enterprise {
  party_id?: string;
  id?: string;
  email?: string;
  native_language?: 'NL' | 'FR' | 'EN';
  telephonenr?: string;
  iban?: string;
  address?: Address;
  name: string;
  cbe?: string;
  company_registration?: Partial<CompanyRegistration>;
  rsz_nr?: string;
  founding_date?: string;
  legal_form?: string;
  commercial_name?: string;
  main_activity?: string;
  website?: string;
  isExisting?: boolean;
  description?: string;
  turnover?: {
    amount: number;
  };
  personnel_details?: {
    total_amount: number;
  };
  contact_person?: {
    name: string;
  };
}

export enum ToolbarItemStatus {
  CHECKED,
  SKIPPED,
}

export interface ProspectSmtRef {
  id: string;
  type: string;
  self: string;
}

export interface HomeSpecs {
  prospect_has_claims: boolean;
}

export interface CarSpecs {
  baloise_bonus_malus_safe: boolean;
  pnp_good_customer: boolean;
}

export interface Specs {
  home: HomeSpecs;
  car: CarSpecs;
}

export interface RiskObjectRevision {
  id: string;
  self: string;
  type: string;
  driver_id?: string;
  beneficiary_id?: string;
}

export interface LouiseSessionObject {
  id: string;
  companies: string[];
  insurance_types: string[];
  car_tags: string[];
  home_tags: string[];
  family_tags: string[];
  legal_tags: string[];
  prospect: ProspectSmtRef;
  risk_objects_revisions: RiskObjectRevision[];
  has_risk_analysis: boolean;
  specs: Specs;
  distribution_id: string;
  broker_id: string;
  created_at: string;
  modified_at: string;
  status: string;
  type: string;
  country_code: string;
}
