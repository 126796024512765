import { extendTheme } from '@chakra-ui/react';
import fontSizes from './foundations/fontSizes';
import lineHeights from './foundations/lineHeights';
import styles from './foundations/styles';
import sizes from './foundations/sizes';
import spacing from './foundations/spacing';
import textStyles from './foundations/textStyles';
import colors from './foundations/colors';
import baseColors, { Swatch } from './foundations/baseColors';
import shadows from './foundations/shadows';
import breakpoints from './foundations/breakpoints';
import radii from './foundations/radii';
import components from './components';
import { ThemeName } from './themes';
import { generateThemeColors } from './utils/themegenerator';

const extendedTheme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  colors: baseColors,
  space: spacing,
  sizes,
  lineHeights,
  fontSizes,
  textStyles,
  shadows,
  styles,
  radii,
  components,
  fonts: {
    heading: `Circular, sans-serif`,
    body: `Circular, sans-serif`,
  },
});

// We explicitly override the breakpoints here because the combination of
// our own breakpoints and the built-in ones causes clashes
const baseTheme = { ...extendedTheme, breakpoints } as {
  colors: typeof baseColors;
  breakpoints: typeof breakpoints;
  space: typeof spacing;
  sizes: typeof sizes;
  lineHeights: typeof lineHeights;
  fontSizes: typeof fontSizes;
  textStyles: typeof textStyles;
  shadows: typeof shadows;
  styles: typeof styles;
  radii: typeof radii;
};

export type CustomTheme = typeof extendedTheme &
  typeof baseTheme & { colors: Swatch };

export const getTheme = (
  themeName: ThemeName,
  fallback = {
    primary: '#FF8000',
    secondary: '#6F558A',
  },
): CustomTheme => {
  const themeColors =
    colors[themeName] ||
    generateThemeColors(fallback?.primary, fallback?.secondary);

  return {
    ...baseTheme,
    colors: {
      ...baseTheme.colors,
      ...themeColors,
    },
  };
};

export default baseTheme;
